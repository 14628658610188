import { api } from "../../axios/api";

export const getAvenants = async (params = {}) => {
  return api.get(`/gestion-production/avenants`, {
    params,
  });
};

export const makePayment = async (uuid, data) => {
  return api.post(`/gestion-production/avenants/make-payment/${uuid}`, data);
};
