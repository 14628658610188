import React, { useState } from "react";
import { Button, Form, Message, Modal } from "semantic-ui-react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import * as compagniesPartnersService from "../../services/compagniesPartnersService";
import * as intermediairesService from "../../services/intermediairesService";
import { useEffect } from "react";
import { getTypeIntermediaireText } from "../../utils/commons";

const validationSchema = yup.object().shape({
  idintermediaires: yup.number().required(),
});

function CompanyCourtierModal({
  callback = () => {},
  open = false,
  setOpen = () => {},
}) {
  const [intermediairesOptions, setIntermediairesOptions] = useState([]);
  const formik = useFormik({
    initialValues: {
      idintermediaires: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      compagniesPartnersService
        .create(values)
        .then(
          () => {
            toast(`Partenaire ajouté avec succès.`, {
              type: "success",
              theme: "colored",
            });
            handleReset();
            callback();
            setOpen(false);
          },
          (reason) => {
            toast(`${reason?.response?.data || reason?.message}`, {
              type: "error",
              theme: "colored",
            });
          }
        )
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const {
    errors,
    handleSubmit,
    isSubmitting,
    setSubmitting,
    handleReset,
    setFieldValue,
  } = formik;

  const fetchIntermediaires = async () => {
    try {
      const response = await intermediairesService.fetchAll();
      if (response.status === 200 && Array.isArray(response.data)) {
        let options = [
          {
            key: "DEFAULT",
            text: "Choisir...",
            value: "",
          },
        ];
        for (const intermediaire of response.data) {
          options.push({
            key: `${intermediaire.idintermediaires}`,
            text: `${intermediaire.denomination} (${getTypeIntermediaireText(
              intermediaire.type_intermediaire
            )})`,
            value: intermediaire.idintermediaires,
          });
        }
        setIntermediairesOptions(options);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchIntermediaires();
    return () => {};
  }, []);

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      as={Form}
      size="tiny"
      closeIcon
    >
      <Modal.Header>Ajouter un intermédiaire</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Message>
            <p>
              Ajoutez un intermédiaire(Courtier, Agent Général) comme partenaire
              de votre compagnie.
            </p>
          </Message>
          <Form.Field required error={"idintermediaires" in errors}>
            <label>Intermédiaire</label>
            <Form.Select
              search
              scrolling
              fluid
              onChange={(e, data) =>
                setFieldValue("idintermediaires", data.value)
              }
              options={intermediairesOptions}
              placeholder="Choisr..."
            />
            <small className="field-error">{errors.idintermediaires}</small>
          </Form.Field>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={() => setOpen(false)}>
          Annuler
        </Button>
        <Button
          content="Enregistrer"
          icon="save"
          onClick={handleSubmit}
          positive
          type="submit"
          loading={isSubmitting}
        />
      </Modal.Actions>
    </Modal>
  );
}

export default CompanyCourtierModal;
