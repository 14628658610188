import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AssuresDetails from "../pages/assures/AssuresDetails";
import AssuresList from "../pages/assures/AssuresList";
import CompagniesList from "../pages/comagnies/CompagniesList";
import CompanyDetails from "../pages/comagnies/CompanyDetails";
import CompanyAgencyDetails from "../pages/compagnies_agencies/CompanyAgencyDetails";
import Dashboard from "../pages/dashboard/Dashboard";
import CompanyPartnersDetails from "../pages/partenaires/CompanyPartnersDetails";
import CompanyPartnersList from "../pages/partenaires/CompanyPartnersList";
import SouscripteursList from "../pages/souscripteurs/SouscripteursList";
import UsersList from "../pages/users/UsersList";
import SouscriptionMonoList from "../pages/souscriptions/SouscriptionMonoList";
import AvenantsList from "../pages/avenants/AvenantsList";
import SinistresList from "../pages/sinistres/SinistresList";
import SinistreAdd from "../pages/sinistres/SinistreAdd";
import BordereauProduction from "../pages/etats/BordereauProduction";
import CommandesList from "../pages/module_gestion_attestation/company/commandes/CommandesList";
import MagasinsList from "../pages/module_gestion_attestation/company/magasins/MagasinsList";
import CommandesDetails from "../pages/module_gestion_attestation/company/commandes/CommandeDetails";
import DeliveryDetails from "../pages/module_gestion_attestation/company/deliveries/DeliveryDetails";
import RequestsList from "../pages/module_gestion_attestation/company/requests/RequestsList";
import RequestDetails from "../pages/module_gestion_attestation/company/requests/RequestsDetails";
import DistributionsRequestsList from "../pages/module_gestion_attestation/company/distributions_requests/DistributionsRequestsList";
import DistributionsRequestsDetails from "../pages/module_gestion_attestation/company/distributions_requests/DistributionsRequestsDetails";
import DirectivesTarificationsList from "../pages/module_production/company/parametres/tarification/directives_tarifications/DirectivesTarificationsList";
import DirectivesTarificationsDetails from "../pages/module_production/company/parametres/tarification/directives_tarifications/DirectivesTarificationsDetails";
import GarantiesComplementairesListPage from "../pages/module_production/company/parametres/tarification/garanties_complementaires/GarantiesComplementairesListPage";
import MagasinStocks from "../pages/module_gestion_attestation/company/magasins/stocks/MagasinStocks";
import MagasinStockDetails from "../pages/module_gestion_attestation/company/magasins/stocks/MagasinStockDetails";
import PolicesList from "../pages/module_production/polices/PolicesList";
import PoliceDetails from "../pages/module_production/polices/PoliceDetails";

function CompanyRoutes() {
  return (
    <Routes>
      <Route path="/">
        <Route index element={<Dashboard />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="details" element={<CompanyDetails />} />
        <Route path="administration/compagnies/*">
          <Route index element={<CompagniesList />} />
          <Route path="details/:uuid" element={<CompanyDetails />} />
        </Route>
        <Route path="administration/users/*">
          <Route index element={<UsersList />} />
          <Route path="details/:uuid" element={<CompanyDetails />} />
        </Route>
        <Route path="assures/*">
          <Route index element={<AssuresList />} />
          <Route path="details/:id" element={<AssuresDetails />} />
        </Route>
        <Route path="souscripteurs/*">
          <Route index element={<SouscripteursList />} />
          <Route path="details/:id" element={<AssuresDetails />} />
        </Route>
        <Route path="souscriptions/*">
          <Route path="mono" element={<SouscriptionMonoList />} />

          <Route path="details/:id" element={<AssuresDetails />} />
        </Route>
        <Route path="agences/*">
          <Route index element={<SouscriptionMonoList />} />
          <Route path="details/:id" element={<CompanyAgencyDetails />} />
        </Route>
        <Route path="partenaires/*">
          <Route index element={<CompanyPartnersList />} />
          <Route path="details/:uuid" element={<CompanyPartnersDetails />} />
        </Route>
        <Route path="polices/*">
          <Route index element={<PolicesList />} />
          <Route path="details/:id" element={<PoliceDetails />} />
        </Route>
        <Route path="avenants/*">
          <Route index element={<AvenantsList />} />
          <Route path="details/:id" element={<PoliceDetails />} />
        </Route>
        <Route path="sinistres/*">
          <Route index element={<SinistresList />} />
          <Route path="create" element={<SinistreAdd />} />
          <Route path="details/:id" element={<PoliceDetails />} />
        </Route>
        <Route path="etats/*">
          <Route
            path="bordereau-de-production"
            element={<BordereauProduction />}
          />
          <Route path="details/:id" element={<PoliceDetails />} />
        </Route>
        <Route path="stocks/*">
          <Route path="demandes/*">
            <Route index element={<RequestsList />} />
            <Route path="details/:uuid" element={<RequestDetails />} />
          </Route>
          <Route path="commandes/*">
            <Route index element={<CommandesList />} />
            <Route path="details/:uuid" element={<CommandesDetails />} />
            <Route path="delivery/:uuid" element={<DeliveryDetails />} />
          </Route>
          <Route path="distributions-requests/*">
            <Route index element={<DistributionsRequestsList />} />
            <Route
              path="details/:uuid"
              element={<DistributionsRequestsDetails />}
            />
          </Route>
          <Route path="magasins/*">
            <Route index element={<MagasinsList />} />
            <Route path="stocks/*">
              <Route path=":uuid" element={<MagasinStocks />} />
              <Route path="stocks-magasins/:uuid" element={<MagasinStockDetails />} />
            </Route>
          </Route>
        </Route>
        <Route path="production/*">
          <Route path="parametres-tarification/*">
            <Route path="directives/*">
              <Route index element={<DirectivesTarificationsList />}></Route>
              <Route
                path="details/:uuid"
                element={<DirectivesTarificationsDetails />}
              />
            </Route>
            <Route path="garanties-complementaires/*">
              <Route
                index
                element={<GarantiesComplementairesListPage />}
              ></Route>
            </Route>
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}

export default CompanyRoutes;
