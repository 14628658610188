import React from "react";
import { Routes, Route } from "react-router-dom";
import CompagniesList from "../pages/comagnies/CompagniesList";
import CompanyDetails from "../pages/comagnies/CompanyDetails";
import Dashboard from "../pages/dashboard/Dashboard";
import CarBrandsPage from "../pages/dictionnaire/CarBrandsPage";
import CategoriesContrats from "../pages/dictionnaire/CategoriesContrats";
import CivilitiesPage from "../pages/dictionnaire/CivilitiesPage";
import CountriesPage from "../pages/dictionnaire/CountriesPage";
import JobsPage from "../pages/dictionnaire/JobsPage";
import PiecesOfficiellesPage from "../pages/dictionnaire/PiecesOfficiellesPage";
import SourcesEnergies from "../pages/dictionnaire/SourcesEnergies";
import ZonesCirculation from "../pages/dictionnaire/ZonesCirculation";
import IntermediaireDetails from "../pages/intermediaires/IntermediaireDetails";
import IntermediairesList from "../pages/intermediaires/IntermediairesList";
import SouscriptionList from "../pages/souscriptions/SouscriptionMonoList";
import UsersList from "../pages/users/UsersList";
import Stocks from "../pages/module_gestion_attestation/pool/stocks/Stocks";
import StockDetails from "../pages/module_gestion_attestation/pool/stocks/StockDetails";
import RequestsList from "../pages/module_gestion_attestation/pool/requests/RequestsList";
import RequestsDetails from "../pages/module_gestion_attestation/pool/requests/RequestsDetails";
import DirectivesTarificationsList from "../pages/module_production/pool/parametres/tarification/directives_tarifications/DirectivesTarificationsList";
import DirectivesTarificationsDetails from "../pages/module_production/pool/parametres/tarification/directives_tarifications/DirectivesTarificationsDetails";
import InsurancesDurationListPage from "../pages/module_production/pool/parametres/tarification/insurances_durations/InsurancesDurationListPage";
import GarantiesListPage from "../pages/module_production/pool/parametres/tarification/liste_garanties/GarantiesListPage";
import GarantiesListDetails from "../pages/module_production/pool/parametres/tarification/liste_garanties/GarantiesListDetails";
import PolicesList from "../pages/module_production/polices/PolicesList";
import PoliceDetails from "../pages/module_production/polices/PoliceDetails";

function PoolRoutes() {
  return (
    <Routes>
      <Route path="/">
        <Route index element={<Dashboard />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="administration/compagnies/*">
          <Route index element={<CompagniesList />} />
          <Route path="details/:uuid" element={<CompanyDetails />} />
        </Route>
        <Route path="administration/users/*">
          <Route index element={<UsersList />} />
          <Route path="details/:uuid" element={<CompanyDetails />} />
        </Route>
        <Route path="administration/intermediaires/*">
          <Route index element={<IntermediairesList />} />
          <Route path="details/:uuid" element={<IntermediaireDetails />} />
        </Route>
        <Route path="souscriptions/*">
          <Route index element={<SouscriptionList />} />
        </Route>
        <Route path="dictionnaire/*">
          <Route path="categories-contrats" element={<CategoriesContrats />} />
          <Route path="sources-energies" element={<SourcesEnergies />} />
          <Route path="zones-circulation" element={<ZonesCirculation />} />
          <Route path="pays" element={<CountriesPage />} />
          <Route path="civilites" element={<CivilitiesPage />} />
          <Route path="professions" element={<JobsPage />} />
          <Route path="car-brands" element={<CarBrandsPage />} />
          <Route
            path="pieces-officielles"
            element={<PiecesOfficiellesPage />}
          />
        </Route>
        <Route path="stocks/*">
          <Route index element={<Stocks />} />
          <Route path="details/:uuid" element={<StockDetails />} />
          <Route path="demandes/*">
            <Route index element={<RequestsList />} />
            <Route path="details/:uuid" element={<RequestsDetails />} />
          </Route>
        </Route>
        <Route path="production/*">
          <Route path="parametres-tarification/*">
            <Route path="directives/*">
              <Route index element={<DirectivesTarificationsList />}></Route>
              <Route
                path="details/:uuid"
                element={<DirectivesTarificationsDetails />}
              />
            </Route>
            <Route
              path="durees-assurances"
              element={<InsurancesDurationListPage />}
            />
            <Route path="liste-garanties/*">
              <Route index element={<GarantiesListPage />} />
              <Route path=":uuid" element={<GarantiesListDetails />} />
            </Route>
          </Route>
          <Route path="polices/*">
            <Route index element={<PolicesList />} />
            <Route path="details/:id" element={<PoliceDetails />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}

export default PoolRoutes;
