import React, {  } from "react";
import {
  Grid,
  GridRow,
  Header,
  Input,
} from "semantic-ui-react";
import Page from "../../../components/Page";
import PolicesTable from "../../../components/production/PolicesList";

function PolicesList() {
  return (
    <Page title="Liste des polices">
      <GridRow className="Souscriptions">
        <Grid stackable>
          <Grid.Column
            width={8}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Grid>
              <Grid.Column>
                <Header>Liste des polices</Header>
              </Grid.Column>
            </Grid>
          </Grid.Column>
          <Grid.Column
            width={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Input placeholder="Rechercher..." className="search">
              <input name="search" />
            </Input>
          </Grid.Column>
        </Grid>
        <PolicesTable />
      </GridRow>
    </Page>
  );
}

export default PolicesList;
