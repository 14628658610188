import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbDivider,
  BreadcrumbSection,
  Button,
  Divider,
  Dropdown,
  Grid,
  GridRow,
  Header,
  Icon,
  Input,
  Pagination,
  Segment,
  Table,
} from "semantic-ui-react";
import CategorieContratFormModal from "../../components/modalForms/CategorieContratFormModal";
import Page from "../../components/Page";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import * as categoriesContratsService from "../../services/categoriesContratsService";

function CategoriesContrats() {
  const [categories, setCategories] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    totalItems: 0,
    prevPage: null,
    nextPage: null,
    limit: 10,
  });

  const handlePageChange = (e, { activePage }) => {
    setPagination((prev) => ({ ...prev, currentPage: activePage }));
  };

  const fetchCategories = async () => {
    setIsLoading(true);
    try {
      const response = await categoriesContratsService.fetchAll({
        page: pagination.currentPage,
        limit: pagination.limit,
        search: search || undefined,
      });
      console.log(response);

      if (response.status === 200) {
        const {
          categories,
          totalPages,
          currentPage,
          totalItems,
          nextPage,
          prevPage,
        } = response.data;
        setCategories(categories);
        setPagination((prev) => ({
          ...prev,
          currentPage: currentPage || prev.currentPage,
          totalPages: totalPages || prev.totalPages,
          totalItems: totalItems || prev.totalItems,
          nextPage,
          prevPage,
        }));
      }
    } catch (error) {
      toast.error("Erreur lors du chargement des catégories de contrats");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchChange = (e) => {
    const searchValue = e.target.value;
    setSearch(searchValue);
    setPagination((prev) => ({ ...prev, currentPage: 1 }));
  };

  const handleDeleteCategory = async (id) => {
    const result = await Swal.fire({
      title: "Êtes-vous sûr ?",
      text: "Vous ne pourrez pas revenir en arrière !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimer",
      cancelButtonText: "Annuler",
    });

    if (result.isConfirmed) {
      setIsDeleting(true);
      try {
        const response = await categoriesContratsService.remove(id);
        if (response.status === 200) {
          toast.success("Catégorie supprimée avec succès");
          fetchCategories();
        }
      } catch (error) {
        toast.error("Erreur lors de la suppression de la catégorie");
      } finally {
        setIsDeleting(false);
      }
    }
  };

  const handleCloseModal = () => {
    setSelectedCategoryId(null);
    setOpen(false);
  };

  useEffect(() => {
    fetchCategories();
  }, [pagination.currentPage, search]);

  return (
    <Page title="Catégories de contrats">
      <GridRow className="CategoriesContrats">
        <Grid.Row className="!mb-5">
          <Grid.Column width={16}>
            <Breadcrumb>
              <BreadcrumbSection link>Pool</BreadcrumbSection>
              <BreadcrumbDivider icon="right chevron" />
              <BreadcrumbSection link>Dictionnaire</BreadcrumbSection>
              <BreadcrumbDivider icon="right arrow" />
              <BreadcrumbSection active>
                Catégories de contrat
              </BreadcrumbSection>
            </Breadcrumb>
            <Divider />
          </Grid.Column>
        </Grid.Row>
        <Grid stackable>
          <Grid.Column
            width={8}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Header>Catégories de contrats</Header>
          </Grid.Column>
          <Grid.Column
            width={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Input
              placeholder="Rechercher..."
              className="search"
              value={search}
              onChange={handleSearchChange}
            />
            <Button
              style={{ marginLeft: "10px" }}
              circular
              icon={<Icon name="add" />}
              onClick={() => setOpen(true)}
            />
          </Grid.Column>
        </Grid>
        <Segment loading={isLoading}>
          <Table striped celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell>Libelle</Table.HeaderCell>
                <Table.HeaderCell>Description</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {categories.map((category, index) => (
                <Table.Row key={`${category.idcategories}`}>
                  <Table.Cell>
                    {(pagination.currentPage - 1) * pagination.limit +
                      index +
                      1}
                  </Table.Cell>
                  <Table.Cell>{category.libelle}</Table.Cell>
                  <Table.Cell>{category.description}</Table.Cell>
                  <Table.Cell textAlign="right">
                    <Dropdown
                      pointing="right"
                      icon={<Icon name="ellipsis vertical" />}
                    >
                      <Dropdown.Menu>
                        <Dropdown.Item
                          icon={<Icon name="eye" />}
                          content="Détails"
                          onClick={() => {
                            const id = category.idcategories_contrats;
                            setSelectedCategoryId(id);
                            setOpen(true);
                          }}
                        />
                        <Dropdown.Item
                          icon={<Icon name="edit" />}
                          content="Editer"
                          onClick={() => {
                            const id = category.idcategories_contrats;
                            setSelectedCategoryId(id);
                            setOpen(true);
                          }}
                        />
                        <Dropdown.Item
                          icon={<Icon name="trash" />}
                          content="Supprimer"
                          disabled={isDeleting}
                          onClick={() =>
                            handleDeleteCategory(category.idcategories_contrats)
                          }
                        />
                      </Dropdown.Menu>
                    </Dropdown>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan="4" textAlign="right">
                  <Pagination
                    activePage={pagination.currentPage}
                    totalPages={pagination.totalPages}
                    onPageChange={handlePageChange}
                    disabled={isLoading}
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </Segment>
      </GridRow>
      <CategorieContratFormModal
        open={open}
        setOpen={setOpen}
        selectedCategoryId={selectedCategoryId}
        handleClose={handleCloseModal}
        callback={() => {
          fetchCategories();
          handleCloseModal();
        }}
      />
    </Page>
  );
}

export default CategoriesContrats;
