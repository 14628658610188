import { api } from "../axios/api";

export const findAll = async () => {
  return api.get(`gestion-production/souscriptions`);
};

export const fetchOne = async (id) => {
  return api.get(`gestion-production/souscriptions/${id}`);
};
export const create = async (data) => {
  return api.post(`gestion-production/souscriptions`, data);
};

export const deleteSouscription = async (uuid) => {
  return api.delete(`gestion-production/souscriptions/${uuid}`);
};
